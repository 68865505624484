import {
  createApiFactory,
  createPlugin,
  createComponentExtension,
  createRouteRef,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { IdpApiClient, idpApiRef } from './api';

export const entityContentRouteRef = createRouteRef({
  id: 'Idp Entity Content',
});

export const idpPlugin = createPlugin({
  id: 'idp-frontend',
  apis: [
    createApiFactory({
      api: idpApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) =>
        new IdpApiClient({ discoveryApi, identityApi }),
    }),
  ],
  routes: {
    entityContent: entityContentRouteRef,
  },
});

export const IdpMainPage = idpPlugin.provide(
  createComponentExtension({
    name: 'IdpMainPage',
    component: {
      lazy: () => import('./components/IdpMainPage').then(m => m.IdpMainPage),
    },
  }),
);

export const IdpCreatePage = idpPlugin.provide(
  createComponentExtension({
    name: 'IdpCreatePage',
    component: {
      lazy: () =>
        import('./components/CreateEntityPage').then(m => m.TemplatePage),
    },
  }),
);

export const IdpPage = idpPlugin.provide(
  createComponentExtension({
    name: 'IdpPage',
    component: {
      lazy: () =>
        import('./components/IdpPage').then(m => m.FilterableTablePage),
    },
  }),
);
