import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import LogoIcon from './LogoIcon';
import PersonIcon from '@material-ui/icons/Person';
import { PersonRemoveIcon } from './PersonRemoveIcon';
import { AppRegistrationIcon } from './AppRegistrationIcon';
import MemoryIcon from '@material-ui/icons/Memory';
import KitchenIcon from '@material-ui/icons/Kitchen';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import List from '@material-ui/icons/List';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import InstallMobileIcon from '@material-ui/icons/SystemUpdate';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import NotificationsIcon from '@material-ui/icons/Notifications';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import EcoIcon from '@material-ui/icons/Eco';
import { NavLink } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { MyGroupsSidebarItem } from '@backstage/plugin-org';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import FunctionsIcon from '@material-ui/icons/Functions';
import SettingsIcon from '@material-ui/icons/Settings';
import { AWSLogoIcon } from './AWSLogoIcon';
import { AzureLogoIcon } from './AzureLogoIcon';
import { MongoDBLogoIcon } from './MongoDBLogoIcon';
import { InfraWalletIcon } from '@electrolux-oss/plugin-infrawallet';
import GroupIcon from '@material-ui/icons/Group';

import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  SidebarSubmenu,
  SidebarSubmenuItem,
  useSidebarOpenState,
  Link,
} from '@backstage/core-components';
import {
  AZURE_AD_GROUP_ADP_MODULE,
  AZURE_AD_GROUP_ADP_PLATFORM,
  AZURE_AD_GROUP_ADP_TEAM,
  AZURE_AD_GROUP_DPO_DEVELOPERS,
  AZURE_AD_GROUP_IOT_DEV,
  AZURE_AD_GROUP_IOT_PRODUCTION,
  AZURE_AD_GROUP_IOT_PRODUCTION_AMER,
  AZURE_AD_GROUP_IOT_PRODUCTION_APMEA,
  AZURE_AD_GROUP_IOT_PRODUCTION_EU,
  AZURE_AD_GROUP_IOT_STAGING,
  getPhoenixUser,
  hasOwnership,
} from '@internal/plugin-phoenix-common';

import { useApp } from '@backstage/core-plugin-api';
import { AdpIcon } from '@internal/plugin-adp';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 15,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link
        component={NavLink}
        to="/"
        underline="none"
        className={classes.link}
      >
        {isOpen ? <h1>&lt;Phoenix/&gt;</h1> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const { identity } = getPhoenixUser();
  const app = useApp();

  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />
        <SidebarDivider />
        {hasOwnership(identity, AZURE_AD_GROUP_DPO_DEVELOPERS) && (
          <SidebarItem icon={LocalCafeIcon} text="IDP">
            <SidebarSubmenu title="Catalog">
              <SidebarSubmenuItem
                title="Projects / Systems"
                to="catalog/idp#project"
                icon={app.getSystemIcon('kind:system')}
              />
              <SidebarSubmenuItem
                title="Services"
                to="catalog/idp#service"
                icon={app.getSystemIcon('kind:component')}
              />
              <SidebarSubmenuItem
                title="APIs"
                to="catalog?filters[kind]=api"
                subtitle="(coming soon!)"
                icon={app.getSystemIcon('kind:api')}
              />
              <SidebarDivider />
              <SidebarSubmenuItem
                title="Infrastructure"
                to="catalog/idp"
                icon={app.getSystemIcon('kind:resource')}
              />
              <SidebarDivider />
              <SidebarSubmenuItem
                title="Groups"
                to="catalog?filters[kind]=group"
                icon={app.getSystemIcon('kind:group')}
              />
              <SidebarSubmenuItem
                title="Users"
                to="catalog?filters[kind]=user"
                icon={app.getSystemIcon('kind:user')}
              />
              <SidebarSubmenuItem
                title="Templates"
                to="catalog?filters[kind]=template"
                icon={app.getSystemIcon('scaffolder')}
              />
              <SidebarSubmenuItem
                title="Tasks"
                to="catalog/tasks"
                icon={FormatListBulletedIcon}
              />
              <SidebarSubmenuItem
                icon={HelpOutlineIcon}
                title="Docs"
                to="https://dpo-electrolux.atlassian.net/wiki/spaces/GI/pages/1163034651/Developer+Platform+IDP"
              />
            </SidebarSubmenu>
          </SidebarItem>
        )}
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          {(hasOwnership(identity, AZURE_AD_GROUP_IOT_DEV) ||
            hasOwnership(identity, AZURE_AD_GROUP_IOT_STAGING) ||
            hasOwnership(identity, AZURE_AD_GROUP_IOT_PRODUCTION) ||
            hasOwnership(identity, AZURE_AD_GROUP_IOT_PRODUCTION_EU) ||
            hasOwnership(identity, AZURE_AD_GROUP_IOT_PRODUCTION_APMEA) ||
            hasOwnership(identity, AZURE_AD_GROUP_IOT_PRODUCTION_AMER) ||
            hasOwnership(identity, AZURE_AD_GROUP_DPO_DEVELOPERS)) && (
            <SidebarItem icon={MemoryIcon} text="IoT">
              <SidebarSubmenu title="IoT Platform">
                <SidebarSubmenuItem
                  icon={PersonIcon}
                  title="Users"
                  to="user-page"
                />
                <SidebarSubmenuItem
                  icon={KitchenIcon}
                  title="Appliances (ECP)"
                  to="appliances"
                />
                <SidebarSubmenuItem
                  icon={KitchenIcon}
                  title="Appliances (OCP)"
                  to="ocp-appliance-page"
                />
                <SidebarDivider />
                <SidebarSubmenuItem
                  icon={List}
                  title="OCP Events Count"
                  to="hacl-events-count"
                />
                <SidebarSubmenuItem
                  icon={AppRegistrationIcon}
                  title="Registration"
                  subtitle="Add/Remove appliances"
                  to="appliance-registration"
                />
                <SidebarDivider />
                {(hasOwnership(identity, AZURE_AD_GROUP_DPO_DEVELOPERS) ||
                  hasOwnership(
                    identity,
                    'group:default/dpo-plugin-cf_read',
                  )) && (
                  <SidebarSubmenuItem
                    icon={AssignmentIcon}
                    title="Config Files"
                    to="config-files"
                  />
                )}
                {hasOwnership(identity, AZURE_AD_GROUP_DPO_DEVELOPERS) && (
                  <>
                    <SidebarDivider />
                    <SidebarSubmenuItem
                      icon={NotificationsIcon}
                      title="CNS Push Notifications"
                      to="cns"
                    />
                  </>
                )}
              </SidebarSubmenu>
            </SidebarItem>
          )}
          {(hasOwnership(identity, AZURE_AD_GROUP_DPO_DEVELOPERS) ||
            hasOwnership(
              identity,
              'group:default/dpo-plugin-mobile-circleci_[admin|testers]',
            )) && (
            <SidebarItem
              icon={InstallMobileIcon}
              text="Mobile CircleCI"
              to="mobile-circleci"
            />
          )}
          <SidebarItem icon={AccountTreeOutlinedIcon} text="Org">
            <SidebarSubmenu title="Org">
              <SidebarSubmenuItem
                icon={AccountTreeOutlinedIcon}
                title="Org Chart"
                to="org-chart"
              />
              <SidebarSubmenuItem
                icon={GroupIcon}
                title="Team Management"
                to="org-teams"
              />
            </SidebarSubmenu>
          </SidebarItem>
          <SidebarItem icon={KitchenIcon} text="LabMate" to="labmate" />
          {hasOwnership(identity, 'group:default/dpo-plugin-ccpa_user') && (
            <SidebarItem icon={PersonRemoveIcon} to="ccpa" text="CCPA" />
          )}
          {hasOwnership(
            identity,
            'group:default/dpo-plugin-cost-explorer_[user|admin]',
          ) && (
            <SidebarItem
              icon={InfraWalletIcon}
              to="infrawallet"
              text="InfraWallet"
            />
          )}
          {hasOwnership(identity, 'group:default/dpo-plugin-cost-explorer') && (
            <SidebarGroup label="Menu" icon={<MenuIcon />}>
              <SidebarItem icon={MonetizationOnIcon} text="DX Costs">
                <SidebarSubmenu title="DX Costs">
                  {hasOwnership(
                    identity,
                    'group:default/dpo-plugin-cost-explorer_[user|admin]',
                  ) && (
                    <div>
                      <SidebarSubmenuItem
                        icon={EqualizerIcon}
                        title="Cost Reports"
                        to="cost-explorer/products"
                      />
                      <SidebarSubmenuItem
                        icon={FunctionsIcon}
                        title="Cost Formulas"
                        to="cost-explorer/formula"
                      />
                    </div>
                  )}
                  {hasOwnership(
                    identity,
                    'group:default/dpo-plugin-cost-explorer_[beta|admin]',
                  ) && (
                    <div>
                      <SidebarDivider />
                      <SidebarSubmenuItem
                        icon={AWSLogoIcon}
                        title="AWS"
                        subtitle="Beta"
                        to="cost-explorer/cost-items/aws"
                      />
                      <SidebarSubmenuItem
                        icon={AzureLogoIcon}
                        title="Azure"
                        subtitle="(coming soon)"
                      />
                      <SidebarSubmenuItem
                        icon={MongoDBLogoIcon}
                        title="MongoDB Atlas"
                        subtitle="Beta"
                        to="cost-explorer/mongo-atlas-analyzer"
                      />
                    </div>
                  )}
                  <SidebarDivider />
                  {hasOwnership(
                    identity,
                    'group:default/dpo-plugin-cost-explorer_admin',
                  ) && (
                    <div>
                      <SidebarSubmenuItem
                        icon={SettingsIcon}
                        title="Admin"
                        to="cost-explorer/admin"
                      />
                    </div>
                  )}
                </SidebarSubmenu>
              </SidebarItem>
            </SidebarGroup>
          )}
          {hasOwnership(identity, AZURE_AD_GROUP_DPO_DEVELOPERS) && (
            <SidebarItem
              icon={FormatListBulletedIcon}
              to="catalog-page"
              text="Service Catalog"
            />
          )}
          <SidebarItem icon={MapIcon} to="status-page" text="Status Page" />
          <SidebarItem icon={AdpIcon} text="ADP">
            <SidebarSubmenu title="Appliance Development Portal">
              <SidebarSubmenuItem
                icon={AssignmentIcon}
                title="Documentation"
                to="adp"
              />
              <SidebarSubmenuItem
                icon={KitchenIcon}
                title="PDCL"
                to="adp/pdcl"
              />
              {(hasOwnership(identity, AZURE_AD_GROUP_ADP_TEAM) ||
                hasOwnership(identity, AZURE_AD_GROUP_ADP_MODULE)) && (
                <SidebarSubmenuItem
                  icon={DeveloperBoardIcon}
                  title="Module"
                  to="adp/module"
                />
              )}
              {(hasOwnership(identity, AZURE_AD_GROUP_ADP_TEAM) ||
                hasOwnership(identity, AZURE_AD_GROUP_ADP_PLATFORM)) && (
                <SidebarSubmenuItem
                  icon={ExtensionIcon}
                  title="Appliance Platform"
                  to="adp/platform"
                />
              )}
            </SidebarSubmenu>
          </SidebarItem>
          <SidebarSpace />
          <SidebarDivider />
          {/* </div>
          }
        {/* Global nav, not org-specific */}
          {hasOwnership(identity, AZURE_AD_GROUP_DPO_DEVELOPERS) && (
            <SidebarItem
              icon={CreateComponentIcon}
              to="/catalog/idp/create"
              text="Create..."
            />
          )}
          {/* End global nav */}
          <SidebarDivider />
          <MyGroupsSidebarItem
            singularTitle="My Teams"
            pluralTitle="My Teams"
            icon={app.getSystemIcon('kind:group')!}
            filter={{ 'spec.type': 'team' }}
          />
        </SidebarGroup>
        <SidebarSpace />
        <SidebarDivider />
        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar />}
          to="/settings"
        >
          <SidebarSettings />
          <SidebarItem
            icon={EcoIcon}
            to="$"
            text={
              document.querySelector('#app-version')?.getAttribute('value') ||
              ''
            }
          />
        </SidebarGroup>
      </Sidebar>
      {/* : <Progress />} */}
      {children}
    </SidebarPage>
  );
};
