import {
  createApiRef,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { Models } from '@backstage/plugin-bitbucket-cloud-common';

export const mobileCircleCiApiRef = createApiRef<MobileCircleCiApi>({
  id: 'plugin.mobile-circleci.service',
});

export type Options = {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;
};

export interface MobileCircleCiApi {
  deleteAppstoreConnectGroup(payload: Object): Promise<any>;
  inviteUserToAppstoreConnect(payload: Object): Promise<any>;
  createAppstoreConnectGroup(payload: Object): Promise<any>;
  getUsersForAppstoreConnectGroup(groupName: string): Promise<any>;
  addUserToAppstoreConnectGroup(payload: Object): Promise<any>;
  deleteUserFromAppstoreConnectGroup(payload: Object): Promise<any>;
  getAppstoreConnectUsers(): Promise<any>;
  getBranches: (branch: string) => Promise<Models.Branch[]>;
  getTags: (
    workspace: string,
    repo: string,
    pagelen?: number,
  ) => Promise<Models.Commit[]>;
  getVersionIos(): Promise<any>;
  getVersionAndroid(tracks: string[]): Promise<any>;
  triggerBuild: (payload: object) => Promise<any>;
  getWorkflowStats: () => Promise<any>;
  getPipelineStats: (pipelineID: string) => Promise<any>;
  getTestflightBetaGroups: (branch: string) => Promise<any>;
  getTestflightBetaGroupsForInhouseApps: () => Promise<any>;
  getBetaGroupsByEmail(email: string): Promise<any>;
}

export class MobileCircleCiApiClient implements MobileCircleCiApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async triggerBuild(payload: object): Promise<any> {
    return await this.post('/rc-trigger', payload);
  }

  async getBranches(branch: string): Promise<Models.Branch[]> {
    return await this.get(`/branches?name=${branch}`);
  }

  async getTags(): Promise<Models.Commit[]> {
    return await this.get(`/tags`);
  }

  async getTestflightBetaGroups(): Promise<any> {
    return await this.get(`/groups`);
  }

  async getTestflightBetaGroupsForInhouseApps(): Promise<any> {
    return await this.get(`/groups/inhouse`);
  }

  async getWorkflowStats(): Promise<any> {
    return await this.get('/workflows');
  }

  async getPipelineStats(pipelineID: string): Promise<any> {
    return await this.get(`/pipeline?id=${pipelineID}`);
  }

  async getAppstoreConnectUsers(): Promise<any> {
    return await this.get(`/users`);
  }

  async createAppstoreConnectGroup(payload: object): Promise<any> {
    return await this.post(`/groups/inhouse`, payload);
  }

  async deleteAppstoreConnectGroup(payload: object): Promise<any> {
    return await this.delete(`/groups/inhouse`, payload);
  }

  async getUsersForAppstoreConnectGroup(groupName: string): Promise<any> {
    return await this.get(`/users/groups?groupName=${groupName}`);
  }

  async inviteUserToAppstoreConnect(payload: object): Promise<any> {
    return await this.post('/users', payload);
  }

  async addUserToAppstoreConnectGroup(payload: object): Promise<any> {
    return await this.post('/users/groups', payload);
  }

  async deleteUserFromAppstoreConnectGroup(payload: object): Promise<any> {
    return await this.delete('/users/groups', payload);
  }

  async getBetaGroupsByEmail(email: string): Promise<any> {
    return await this.get(`/groups/email?email=${email}`);
  }

  async getVersionIos(): Promise<any> {
    return await this.get('/version/ios');
  }

  async getVersionAndroid(
    _tracks: string[] = ['production', 'beta'],
  ): Promise<any> {
    return await this.get('/version/android');
  }

  private async get(path: string): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'mobile-circleci',
    )}${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    });

    if (!response.ok) {
      const payload = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      throw new Error(message);
    }
    return await response.json();
  }

  private async post(path: string, payload: object): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'mobile-circleci',
    )}${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const text = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${text}`;
      throw new Error(message);
    }

    return await response.json();
  }
  private async delete(path: string, payload: object): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'mobile-circleci',
    )}${path}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const text = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${text}`;
      throw new Error(message);
    }

    return await response.json();
  }
}
