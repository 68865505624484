import React from 'react';
import { Grid } from '@material-ui/core';

import {
  EntityAboutCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  isKind,
  hasCatalogProcessingErrors,
  isOrphan,
} from '@backstage/plugin-catalog';
import { EntityMembersListCard } from '@backstage/plugin-org';

import { TeamOwnerCard } from '@internal/plugin-teams';
import { EntityAuditListCard } from '@internal/plugin-audits';
import { ToolsCard } from '@internal/plugin-toolset';
import {
  EntityUserProfileCard,
  EntityAwsAccessCard,
} from '@internal/backstage-plugin-org-chart';

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={12}>
          <EntityUserProfileCard variant="gridItem" showLinks={undefined} />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/access" title="Access">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <EntityAwsAccessCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <ToolsCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/audits" title="Audit">
      <EntityAuditListCard />
    </EntityLayout.Route>
  </EntityLayout>
);

const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={8}>
          <EntityMembersListCard />
        </Grid>
        <Grid item xs={4} md={4}>
          <TeamOwnerCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/audits" title="Audit">
      <EntityAuditListCard />
    </EntityLayout.Route>
  </EntityLayout>
);

const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />
  </EntitySwitch>
);
